// Colors palette
$yellow: #ffe200;
$black: #181717;
$black-2: #000;
$white: #fff;
$grey: #d9d9d9;
$grey-2: #eee; // filets
$grey-3: #f6f6f6; // fond de page
$grey-4: #2d2929; // dark bg
$grey-5: #bbb; // focus
$grey-6: #787272;
$grey-7: #8d8d8d;
$red: #cf2140;
$green: #33bc62;

// Named colors
$font-color: $grey-4;
$font-contrast-color: $white;
$bg-color: $white;

// Fonts
$font-stack: helvetica, arial, sans-serif;

// Sizes (these are resolution dependant)
$push-height: var(--push-height);
$nav-height: var(--nav-height);
$header-height: var(--header-height);
$account-height: var(--account-height);
$aside-width: var(--aside-width);
$layout-padding: var(--layout-padding);
$layout-gap: var(--layout-gap);

// Opacity
$disable-opacity: 0.5;

// Z-indexes
$overlay-z: 100;
$chat-z: $overlay-z - 8;
$promo-z: $overlay-z - 7;
$cta-z: $overlay-z - 6;
$pullbox-z: $overlay-z - 5;
$account-z: $overlay-z - 4;
$prompt-z: $overlay-z - 3;
$header-z: $overlay-z - 2;
$push-z: $overlay-z - 1;
$loader-z: $overlay-z + 1;
$dialog-z: $overlay-z + 2;
$axeptio-z: $overlay-z + 3;
$select-z: $overlay-z + 4;
$snackbar-z: $overlay-z + 5;

// Animation
$quick-timing: 0.2s;
$default-timing: 0.35s;
$long-timing: 0.5s;
$default-easing: ease-out;

// Breakpoints
$breakpoints: (
  'x-small': 720px,
  'small': 1024px,
  'medium': 1280px,
  'large': 1440px,
  'x-large': 1920px,
) !default;
