@use 'sass:map';
@use 'sass:list';
@use 'sass:string';

// Layout

@mixin grid(
  $child-selector,
  $cols: 1,
  $horizontal-gap: 0,
  $vertical-gap: 0,
  $center: false
) {
  display: flex;
  flex-flow: row wrap;
  gap: $horizontal-gap;

  @if $center == true {
    justify-content: center;
  }

  #{$child-selector} {
    width: calc((100% - $horizontal-gap * ($cols - 1)) / $cols);
    margin-bottom: $vertical-gap - $horizontal-gap;
  }
}

// Misc

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin delay-animation($delay: 0.05s, $limit: 10) {
  @for $i from 1 through $limit {
    &:nth-child(#{$i}) {
      animation-delay: $i * $delay;
    }
  }

  &:nth-child(n + #{$limit}) {
    animation-delay: $delay * $limit;
  }
}

@mixin underline($height: 2px, $bottom: 0, $radius: 0, $padding: 0) {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: $height;
    background-color: currentcolor;
    border-radius: 6px;
    bottom: $bottom;
    left: $padding;
    right: $padding;
    pointer-events: none;
  }
}

@mixin elevation($level: 1) {
  box-shadow: map.get(
    (
      1: rgb(149 157 165 / 20%) 0 8px 24px,
      2: rgb(100 100 111 / 20%) 0 7px 29px 0,
      3: rgb(0 0 0 / 30%) 0 5px 15px,
    ),
    $level
  );
}

@mixin ghost($gradient: false, $strength: 15%) {
  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;

    @if $gradient == true {
      background: linear-gradient(
        180deg,
        rgb(0 0 0 / 0%) 0%,
        rgb(0 0 0 / #{$strength}) 100%
      );
    } @else {
      background: rgba(0 0 0 / #{$strength});
    }
  }
}

@mixin transition($timing, $easing, $props...) {
  $result: ();

  @for $i from 1 through list.length($props) {
    $prop: list.nth($props, $i);
    $result: list.append($result, $prop);
    $result: list.append($result, $timing);
    $result: list.append($result, $easing);

    @if $i != list.length($props) {
      $result: list.append(
        $result,
        string.unquote(
          $string: ',',
        )
      );
    }
  }

  transition: $result;
}
